import React, { useState, useEffect } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import OGImage from '../images/favicon-black.png';
import dateFormat, { masks } from 'dateformat';
import { URL_API } from 'gatsby-env-variables';
import { fetchJson } from '../helper/fetch-helper';

function PaymentSuccess({ location }) {
  const data = useStaticQuery(
    graphql`
      query {
        login_bg: file(relativePath: { eq: "account-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );
  const [currentUser, setCurrentUser] = useState(null);
  const params = new URLSearchParams(location.search);
  const subscription = params.get('subscription');
  const transaction_id = params.get('transaction_id');
  const customer_id = params.get('customer_id');
  const provider = params.get('provider');
  const bg_login = getImage(data.login_bg);
  // Use like this: bg-accounts-bg bg-cover bg-center
  const loginBg = convertToBgImage(bg_login);
  const [cookies, setCookies] = useCookies(['currentUser']);
  const [date, setDate] = useState(dateFormat('longDate'));

  const url = typeof window !== 'undefined' ? window.location.origin : '';

  useEffect(() => {
    if (!currentUser) {
      setCurrentUser(cookies.currentUser);
    } else {
      updateSubscription();
    }
  }, [currentUser]);

  function updateSubscription() {
    const response = fetchJson(`${URL_API}/payment/update`, 'POST', {
      subscription: subscription,
      provider: provider,
      user_id: currentUser,
      transaction_id: transaction_id,
      customer_id: customer_id,
    }).then((response) => {
      return response.json();
    });
    return response;
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${url}/payment-success`} />
        <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
        <meta property="og:title" content="Thank You | Girar World" />
        <meta property="description" content="Girar, go on a journey" />
        <meta property="og:description" content="Girar, go on a journey" />
        <meta property="og:url" content={`${url}/payment-success`} />
        <title>Thank You | Girar World</title>
        <meta property="og:image" content={`${url}${OGImage}`} />
        <meta name="twitter:url" content={`${url}/payment-success`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WeltgeistKiran" />
        <meta name="twitter:title" content="Thank You | Girar World" />
        <meta name="twitter:description" content="Girar, go on a journey" />
        <meta property="twitter:image" content={`${url}${OGImage}`} />
        <meta property="og:type" content="website" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <BackgroundImage Tag="section" className="bg-center" {...loginBg}>
        <section className="relative w-full h-screen">
          <div className="max-w-screen-2xl mx-auto grid grid-cols-12">
            <div className="col-span-12 mt-20 px-20 text-center">
              <h1 className="font-opensans xl:text-4xl text-2xl text-white">Payment Successful</h1>
              <p className="font-rokkit xl:text-2xl text-base text-white mt-3">Thank you for subscribing to our {subscription} Plan</p>
              <Link to="/account">
                <p className="font-rokkit xl:text-2xl text-base text-white">Click here to go to your account</p>
              </Link>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </>
  );
}

export default PaymentSuccess;
